<template>
  <v-sheet color="grey lighten-5">
    <v-container fluid>
      <v-row
        align="start"
        justify="start"
        class="pa-1"
        v-if="item.on_job_position"
      >
        <v-col cols="12">
          <v-card class="dekra-card">
            <v-card-text class="text-left text-truncate">
              <v-row>
                <v-col cols="11">
                  <div class="title font-weight-bold">
                    {{ item.on_workforce_request.name }}
                    <span
                      v-if="item.on_workforce_request.workforce_request_type"
                      >,
                      {{
                        item.on_workforce_request.workforce_request_type
                          .display_name
                      }}</span
                    >
                  </div>

                  <div class="subtitle-1 font-weight-bold">
                    <v-icon>mdi-briefcase</v-icon>
                    {{ item.on_job_position.name }} @
                    {{ item.on_workforce_request.company.name }}
                  </div>
                  <div class=" body-2">
                    Started at:
                    {{ formatDate(item.on_job_position.started_at) }}
                  </div>
                </v-col>
                <v-col>
                  <v-btn
                    icon
                    color="warning"
                    v-if="userCan('terminate-contract')"
                    @click="
                      jobPosition = item.on_job_position.id;
                      workforce = item.on_workforce_request.id;
                      updateTerminationDialog(true);
                    "
                    ><v-icon>mdi-clipboard-text-off</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="start" justify="start" class="pa-1" v-else>
        <v-col cols="12">
          <v-card class="pa-1" flat>
            <v-toolbar color="grey lighten-3" dense
              ><v-toolbar-title>Info</v-toolbar-title></v-toolbar
            >
            <v-alert
              border="bottom"
              colored-border
              color="primary"
              elevation="2"
              type="info"
            >
              Currently unassigned
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
      <v-divider class="mt-5"></v-divider>
      <v-row
        align="start"
        justify="start"
        class="pa-1"
        v-if="item.job_positions_history"
      >
        <v-col cols="12">
          <v-data-iterator
            hide-default-footer
            :items="item.job_positions_history"
          >
            <template v-slot:header>
              <v-toolbar flat color="transparent ">
                <div class="title font-weight-bold">
                  Workforce Request History
                </div>
              </v-toolbar>
            </template>
            >
            <template v-slot:no-data>
              <v-card class="pa-1" flat>
                <v-toolbar color="grey lighten-3" dense
                  ><v-toolbar-title>Info</v-toolbar-title></v-toolbar
                >
                <v-alert
                  border="bottom"
                  colored-border
                  color="primary"
                  elevation="2"
                  type="info"
                >
                  No previous workforce request history for this employee
                </v-alert>
              </v-card>
            </template>
            >
            <template v-slot:default="props">
              <v-row>
                <v-col
                  v-for="(wf, index) in props.items"
                  :key="index"
                  class="pa-4"
                  cols="12"
                >
                  <v-card class="dekra-card">
                    <v-card-text class="text-left text-truncate">
                      <div class="title font-weight-bold">
                        {{ wf.workforce_request_name }}
                      </div>
                      <div class="subtitle-1 font-weight-bold">
                        <v-icon left>mdi-briefcase</v-icon>
                        {{ wf.job_position_name }} @ {{ wf.client }}
                      </div>
                      <div class="  body-2">
                        Started at: {{ formatDate(wf.started_at) }}
                      </div>
                      <div class="  body-2">
                        Terminated at: {{ formatDate(wf.terminated_at) }}
                        <span v-if="wf.termination_reason"
                          >({{ wf.termination_reason }})</span
                        ><span v-else>(No termination reason)</span>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
          <terminate-contract-dialog
            v-if="jobPosition"
            :job-position.sync="jobPosition"
            :employee-id.sync="item.id"
            :workforce-id.sync="workforce"
            :go-to-url="goTo"
            :dialog.sync="showTerminationDialog"
            @dialog-cancel="updateTerminationDialog(false)"
            @dialog-update="updateTerminationDialog"
          ></terminate-contract-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import permission from "@/mixins/permission";
import date from "@/mixins/date";
export default {
  Name: "workforceTab",
  mixins: [permission, date],
  data() {
    return {
      jobPosition: null,
      workforce: null,
      showTerminationDialog: false
    };
  },
  components: {
    TerminateContractDialog: () =>
      import("@/components/core/dialogs/TerminateContract")
  },
  props: {
    item: {
      required: true,
      type: [Object, Array]
    }
  },
  computed: {
    goTo() {
      return "/employee/show/" + this.item.id;
    }
  },
  methods: {
    updateTerminationDialog(dialog) {
      this.showTerminationDialog = dialog;
    }
  }
};
</script>
<style scoped>
.dekra-card {
  border-bottom: 8px solid #017d40;
}
</style>
